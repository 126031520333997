import _ from "lodash";
import { GET, PUT, POST, PATCH, DELETE } from "../core/config/Api";

export const getTransactionKey = () => {
  return GET(`transaction/key`).then((res) => {
    return res?.data;
  });
};

export const getMyTransaction = (data) => {
  return GET(
    `transactions?walletId=${data.walletId}&status=${data.status}`
  ).then((res) => {
    return res?.data;
  });
};
//

export const fundWalletTest = (walletId, data) => {
  return POST(`transaction/wallet/${walletId}/fund`, data).then((res) => {
    return res?.data;
  });
};

export const transferToWallet = (walletId, data, transactionKey) => {
  return POST(`wallet/${walletId}/wallet-transfer`, data, transactionKey).then(
    (res) => {
      return res?.data;
    }
  );
};

export const transferToBank = (walletId, data) => {
  return POST(`wallet/${walletId}/bank-transfer`, data).then((res) => {
    return res?.data;
  });
};
