export const countryCurrency = [
  {
    id: 1,
    fromCurrency: {
      iso2: "NG",
      currency: "NGN",
      currencyName: "Naira",
      symbol: "₦",
    },
    toCurrency: {
      iso2: "NG",
      currency: "NGN",
      currencyName: "Naira",
      symbol: "₦",
    },
  },
  {
    id: 2,
    fromCurrency: {
      iso2: "GB",
      currency: "GBP",
      currencyName: "Pounds",
      symbol: "£",
      rating: 1,
    },
    toCurrency: {
      iso2: "GB",
      currency: "GBP",
      currencyName: "Pounds",
      symbol: "£",
      rating: 1,
    },
  },
  {
    id: 3,
    fromCurrency: {
      iso2: "US",
      currency: "USD",
      currencyName: "Dollar",
      symbol: "$",
    },
    toCurrency: {
      iso2: "US",
      currency: "USD",
      currencyName: "Dollar",
      symbol: "$",
    },
  },
  {
    id: 4,
    fromCurrency: {
      iso2: "GH",
      currency: "GHS",
      currencyName: "Cedi",
      symbol: "GH₵",
    },
    toCurrency: {
      iso2: "GH",
      currency: "GHS",
      currencyName: "Cedi",
      symbol: "GH₵",
    },
  },
];
