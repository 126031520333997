import EditName from "../../../components/EditName";
import EditPassword from "../../../components/EditPassword";
import EditEmail from "../../../components/EditEmail";
import EditPhoneNumber from "../../../components/EditPhoneNumber";
import CheckboxMain from "../../../components/Checkbox";
import style from "../../../styling/styles.module.css";
import "../../../styling/styles.css";
import { MyProfileProvider } from "./provider";
import EditUsername from "./../../../components/EditUsername";
import EditGender from "./../../../components/EditGender";
import EditSecurityQuestionAndAnswer from "./../../../components/EditSecurityQuestionAndAnswer";
import EditIso2 from "./../../../components/EditIso2";

export default function MyProfile() {
  const {
    user,
    newPassword,
    oldPassword,
    confirmPassword,
    gender,
    securityQuestion,
    securityQuestionAnswer,
    iso2,
    otp,
    showUpdateEmailStep,
    emailUpdate,
    handleEditPassword,
    handleChangePassword,
    handleChange,
    handleChangeGender,
    handleChangeIso2,
    handleChangeSecurityQuestionAndAnswer,
    handleEditName,
    handleEditUsername,
    handleEditEmail,
    handleEditGender,
    handleEditSecurityQuestionAndAnswer,
    handleEditIso2,
    handleChangeOtp,
    sendOtpToNewEmail,
    handleChangeEmailUpdate,
    loading,
  } = MyProfileProvider();
  return (
    <div className={style.wrapContainerLarger}>
      <div className={style.profileSetting}>
        <h3>My Profile</h3>
      </div>
      <div className={style.profileSetting}>
        <ul>
          <li>
            <p>Your Username</p>
          </li>
          <li>
            <h4>{user.username}</h4>
          </li>
          <li>
            <EditUsername
              username={user.username}
              handleChange={handleChange}
              handleEditUsername={handleEditUsername}
              loading={loading}
              label="Edit Username"
            />
          </li>
        </ul>
      </div>
      <div className={style.profileSetting}>
        <ul>
          <li>
            <p>Your Full Name</p>
          </li>
          <li>
            <h4>{`${user.firstname} ${user.lastname}`}</h4>
          </li>
          <li>
            <EditName
              firstname={user.firstname}
              lastname={user.lastname}
              handleChange={handleChange}
              handleEditName={handleEditName}
              loading={loading}
              label="Edit Name"
            />
          </li>
        </ul>
      </div>
      <div className={style.profileSetting}>
        <ul>
          <li>
            <p>Your Password</p>
          </li>
          <li>
            <h4>*******************</h4>
          </li>
          <li>
            <EditPassword
              newPassword={newPassword}
              oldPassword={oldPassword}
              confirmPassword={confirmPassword}
              handleEditPassword={handleEditPassword}
              handleChange={handleChangePassword}
              loading={loading}
              label="Edit Password"
            />
          </li>
        </ul>
      </div>

      <div className={style.profileSetting}>
        <ul>
          <li>
            <p>Your Email Address</p>
          </li>
          <li>
            <h4>{user.email}</h4>
          </li>
          <li>
            <EditEmail
              email={emailUpdate.email}
              otp={otp}
              handleChangeOtp={handleChangeOtp}
              handleChange={handleChangeEmailUpdate}
              handleEditEmail={handleEditEmail}
              sendOtpToNewEmail={sendOtpToNewEmail}
              loading={loading}
              showUpdateEmailStep={showUpdateEmailStep}
              label="Edit Email Address"
            />
          </li>
        </ul>
      </div>
      {user?.gender && (
        <div className={style.profileSetting}>
          <ul>
            <li>
              <p>Your Gender</p>
            </li>
            <li>
              <h4>{user?.gender}</h4>
            </li>
            <li>
              <EditGender
                gender={gender.gender}
                handleChange={handleChangeGender}
                handleEditGender={handleEditGender}
                loading={loading}
                label="Edit Gender"
              />
            </li>
          </ul>
        </div>
      )}

      {user?.securityQuestion && user?.securityQuestionAnswer && (
        <div className={style.profileSetting}>
          <ul>
            <li>
              <p>Your Security Question And Answer</p>
            </li>
            <li>
              <h4>*******************</h4>
            </li>
            <li>
              <EditSecurityQuestionAndAnswer
                securityQuestion={securityQuestion}
                securityQuestionAnswer={securityQuestionAnswer}
                handleChange={handleChangeSecurityQuestionAndAnswer}
                handleEditSecurityQuestionAndAnswer={
                  handleEditSecurityQuestionAndAnswer
                }
                loading={loading}
                label="Edit Security Question And Answer"
              />
            </li>
          </ul>
        </div>
      )}

      {user?.iso2 && (
        <div className={style.profileSetting}>
          <ul>
            <li>
              <p>Your ISO2</p>
            </li>
            <li>
              <h4>{user?.iso2}</h4>
            </li>
            <li>
              <EditIso2
                iso2={iso2.iso2}
                handleChange={handleChangeIso2}
                handleEditIso2={handleEditIso2}
                loading={loading}
                label="Edit Iso2"
              />
            </li>
          </ul>
        </div>
      )}

      {/* <div className={style.profileSetting}>
        <ul>
          <li>
            <p>Your Phone Number</p>
          </li>
          <li>
            <h4>+2349466254728</h4>
          </li>
          <li>
            <EditPhoneNumber label="Edit Phone Number" />
          </li>
        </ul>
      </div> */}
      <div className={style.profileSetting}>
        <h3>Account Setting</h3>
        <div className="profile-check">
          <CheckboxMain label="Enable Two Factor Authentication" />
          <CheckboxMain label="Receive Notification On Mobile Number" />
          <CheckboxMain label="Receive Notification On Email Address" />
          <CheckboxMain label="Receive Newsletter" />
        </div>
      </div>
    </div>
  );
}
