import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
} from "react-router-dom";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import style from "../../styling/styles.css";
import { useLocation } from "react-router-dom";

export default function CurrencyWallet() {
  const location = useLocation();
  const wallet = location?.state?.wallet;

  return (
    <div className="singlewallet-wrap">
      <h4> {`Your ${wallet?.currency?.currencyName} Wallet`}</h4>
      <div className="wallets">
        <div className="wallet gradient-two">
          <div className="currency">
            <p>
              <span>
                <img
                  alt={`${wallet?.currency?.countryName} ${wallet?.currency?.currencyName}`}
                  src={`${wallet?.currency.flag}`}
                />
              </span>
              {`${wallet?.currency?.currencyName} Wallet`}
            </p>
          </div>
          <div className="currency-amount">
            {" "}
            {`${wallet?.currency?.symbol} ${wallet?.balance}`}
          </div>
          <div className="wallet-id">{`Wallet ID: ${wallet._id}`}</div>
          <NavLink className="view-wallet" to="/fund-wallet">
            Fund Wallet
            <span>
              <ArrowCircleRightIcon />
            </span>
          </NavLink>
        </div>
      </div>
    </div>
  );
}
