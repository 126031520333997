import React, { Component } from "react";
import Slider from "react-slick";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
} from "react-router-dom";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import "../styling/CurrencyCarousel.css";
import "slick-carousel/slick/slick-theme.css";
import HomeProvider from "./../pages/home/homeProvider";
import LoadingIcon from "./LoadingIcon";

export default function MultipleItems() {
  const { settings, walletList, loading } = HomeProvider();
  return (
    <div>
      <h4 className="carousel-header">Your wallet</h4>

      <Slider {...settings}>
        {walletList?.length > 0 &&
          walletList?.map((wallet, index) => (
            <div key={index} className="building-block">
              <div
                className={`wallet ${
                  index % 3 === 1
                    ? "gradient-one"
                    : index % 3 === 2
                    ? "gradient-two"
                    : index % 3 === 3
                    ? "gradient-three"
                    : "gradient-five"
                }`}
              >
                <div className="currency">
                  <p>
                    <span>
                      <img
                        alt={`${wallet?.currency?.countryName} ${wallet?.currency?.currencyName}`}
                        src={`${wallet?.currency.flag}`}
                      />
                    </span>
                    {`${wallet?.currency?.currencyName} Wallet`}
                  </p>
                </div>
                <div className="currency-amount">
                  {" "}
                  {`${wallet?.currency?.symbol} ${wallet?.balance}`}
                </div>
                <div className="wallet-id">{`Wallet ID: ${wallet._id}`}</div>
                <NavLink
                  className="view-wallet"
                  to={{
                    pathname: "/currency-wallet",
                    state: { wallet },
                  }}
                >
                  View Wallet
                  <span>
                    <ArrowCircleRightIcon />
                  </span>
                </NavLink>
              </div>
            </div>
          ))}

        <div className="building-block">
          <div className="add-new-wallet">
            <NavLink className="add-wallet" to="/add-wallet">
              <span>
                <AddCircleIcon sx={{ fontSize: 70 }} />
              </span>
              <h4>Add Wallet</h4>
            </NavLink>
          </div>
        </div>
      </Slider>
    </div>
  );
}
