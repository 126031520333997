import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
} from "react-router-dom";
import SelectBank from "../../components/SelectBank";
import BasicTextField from "../../components/BasicTextField";
import WalletDropdown from "../../components/WalletDropdown";
import makeWithdrawIllustration from "../../images/makeWithdrawIllustration.svg";
import style from "../../styling/styles.module.css";
import TransactionProvider from "./TransactionProvider";

export default function Withdraw() {
  const {
    walletList,
    withdraw,
    loading,
    setWithdraw,
    handleChangeWithdraw,
    transferToBank,
  } = TransactionProvider();

  return (
    <div className={style.wrapContainerBorder}>
      <img
        className={style.illustrationImage}
        src={makeWithdrawIllustration}
      ></img>
      <h4>Your Bank Account Details</h4>
      <p>Super quick transfers to your local bank account.</p>
      {/* <div className={style.formSteps}>
        <ul>
          <li>
            <NavLink
              activeClassName={style.activeLink}
              exact
              to="/make-withdraw"
            >
              Bank Details
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName={style.activeLink}
              to="/confirm-bank-details"
            >
              Confirm Details
            </NavLink>
          </li>
        </ul>
      </div> */}
      <form className="withdraw-form" onSubmit={transferToBank}>
        <div>
          <WalletDropdown
            placeholder="Wallet To Withdraw From"
            walletList={walletList}
            fundWalletData={withdraw}
            setFundWalletData={setWithdraw}
          />
        </div>
        <div className={style.inputContainer}>
          <BasicTextField
            label="Amount To Transfer"
            name="amount"
            onChange={handleChangeWithdraw}
            type="number"
            value={withdraw.amount}
          />
        </div>
        <div className={style.inputContainer}>
          <BasicTextField
            label="Account Number"
            name="accountNo"
            onChange={handleChangeWithdraw}
            type="number"
            value={withdraw.accountNo}
          />
        </div>
        <div className={style.inputContainer}>
          <BasicTextField
            label="Bank Code"
            name="bankCode"
            onChange={handleChangeWithdraw}
            type="text"
            value={withdraw.bankCode}
          />
        </div>
        <div className={style.inputContainer}>
          <BasicTextField
            label="Sort Code"
            name="sortCode"
            onChange={handleChangeWithdraw}
            type="text"
            value={withdraw.sortCode}
          />
        </div>
        <div className={style.inputContainer}>
          <BasicTextField
            label="Narration"
            name="narration"
            onChange={handleChangeWithdraw}
            type="text"
            value={withdraw.narration}
          />
        </div>

        {/* <div className={style.inputContainerLess}>
          <SelectBank />
        </div> */}

        <div className="form-button">
          <button className={style.buttonPrimary}>
            {loading ? "Loading..." : "Confirm Details"}
          </button>
          <div className={style.seperator}>
            <span className={style.seperatorText}>or</span>
            <span className={style.seperatorDivider}></span>
          </div>
          <button className={style.buttonUnderline}>Cancel Withdraw</button>
        </div>
      </form>
    </div>
  );
}
