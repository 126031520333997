import { SET_CURRENT_USER } from "../../types/types";

export const setCurrentUser = (user) => {
  return {
    type: SET_CURRENT_USER,
    user,
  };
};

export const logout = () => (dispatch) => {
  localStorage.removeItem("monirate_tk");
  dispatch(setCurrentUser({ isAuthenticated: false }));
};
