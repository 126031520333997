import React, { useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import FundWallet from "../pages/transaction/FundWallet";
import style from "../styling/styles.module.css";

export default function WalletDropdown(props) {
  const { walletList, setFundWalletData, fundWalletData } = props;
  const countries = walletList?.map((country) => country.currency.iso2);
  const getWalletObj = (country) => {
    return walletList.find((wallet) => wallet.currency.iso2 === country);
  };

  return (
    <>
      <p className={style.dropdownLabel}>{props.placeholder}</p>
      <ReactFlagsSelect
        placeholder={props.placeholder}
        className={style.currencyDropdown}
        selected={fundWalletData?.selectedWallet?.currency?.iso2 || ""}
        onSelect={(code) =>
          setFundWalletData({
            ...fundWalletData,
            selectedWallet: getWalletObj(code),
          })
        }
        countries={countries}
        customLabels={{
          US: "US Dollar Wallet",
          GB: "Pound Sterling Wallet",
          NG: "Nigerian Naira Wallet",
          EU: "Euros Wallet",
          NZ: "New Zealand Dollar Wallet",
        }}
        defaultCountry="US"
      />
    </>
  );
}
