import { useState } from "react";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import {
  validateEmail,
  validateOtp,
  validatePassword,
} from "../../../utils/credentialsValidator";
import * as AuthServices from "../../../services/auth";

export const ResetPasswordProvider = () => {
  const { addToast } = useToasts();
  const [credentials, setCredentials] = useState({
    password: "",
    confirmPassword: "",
    otp: "",
    email: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errMsg, setrErrMsg] = useState("");
  const history = useHistory();

  const handleChange = (e) => {
    const { id, value } = e.target;
    setCredentials({ ...credentials, [id]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    // Validation will go here
    const data = {};

    if (validateEmail(credentials.email)) {
      data.email = credentials.email;
    } else {
      addToast("Please, enter valid Email!", { appearance: "error" });
      setLoading(false);
      return;
    }

    if (validatePassword(credentials.password).isPassed) {
      data.password = credentials.password;
    } else {
      addToast(validatePassword(credentials.password).message, {
        appearance: "error",
      });
      setLoading(false);
      return;
    }

    if (validatePassword(credentials.confirmPassword).isPassed) {
      data.password = credentials.confirmPassword;
    } else {
      addToast(validatePassword(credentials.confirmPassword).message, {
        appearance: "error",
      });
      setLoading(false);
      return;
    }

    if (validateOtp(credentials.otp).isPassed) {
      data.otp = credentials.otp;
    } else {
      addToast(validateOtp(credentials.otp).message, {
        appearance: "error",
      });
      setLoading(false);
      return;
    }

    if (credentials.password === credentials.confirmPassword) {
      data.password = credentials.password;
    } else {
      setLoading(false);
      addToast("Passwords do not match!", { appearance: "error" });
      return;
    }

    AuthServices.resetPassword(data)
      .then((res) => {
        setLoading(false);
        setError(false);
        addToast(res?.message, { appearance: "success" });
        setCredentials({
          password: "",
          confirmPassword: "",
          otp: "",
          email: "",
        });
        history.push("/sign-in");
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
        setrErrMsg(
          err.response
            ? err.response.data.message
            : err.message || "Check your Internet connection"
        );
        addToast(
          err.response
            ? err.response.data.message
            : err.message || "Check your Internet connection",
          { appearance: "error" }
        );

        setTimeout(() => {
          setError(false);
        }, 3000);
      });
  };

  return {
    credentials,
    loading,
    errMsg,
    error,
    handleSubmit,
    handleChange,
  };
};
