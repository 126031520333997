import { combineReducers } from "redux";
import transactionReducer from "./../transactions/transactions";
import auth from "../auth/auth";
import user from "../user/user";
import walletReducer from "./../wallet/wallet";

export default combineReducers({
  auth,
  user,
  wallet: walletReducer,
  transaction: transactionReducer,
});
