import axios from "axios";

export default axios.create({
  // baseURL: `https://monirates-gateway.herokuapp.com/v1`,
  baseURL: `https://dev-api.monirates.com/v1`,


  headers: {
    "Content-Type": "application/json",
  },
});
