import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
} from "react-router-dom";
import {
  FormGroup,
  FormControl,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import PasswordInput from "../../components/PasswordInput";
import UseRadioGroup from "../../components/RadioGroup";
import BasicTextField from "../../components/BasicTextField";
import CurrencyDropdown from "../../components/CurrencyDropdown";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import buyRequestIllustration from "../../images/buyRequestIllustration.svg";
import style from "../../styling/styles.module.css";
import RequestProvider from "./RequestProvider";
import { countryCurrency } from "../../utils/countryCurrencyList";

export default function BuyRequest() {
  const {
    setQueryData,
    handleChangeSell,
    sellCurrency,
    setSellData,
    queryData,
    sellData,
    requestList,
    loading,
  } = RequestProvider();
  return (
    <div className={style.wrapContainerBorder}>
      <div className={style.blockIcon}>
        <span>
          <img
            className={style.illustrationImage}
            src={buyRequestIllustration}
          ></img>
        </span>
      </div>
      <h4>Buy Currency</h4>
      <p>
        Select the currency you wish to buy and the currency you wish to pay
        with.
      </p>
      <form className="withdraw-form" onSubmit={(e) => sellCurrency(e, "BUY")}>
        <div className="">
          <CurrencyDropdown
            queryData={sellData}
            requestList={countryCurrency}
            placeholder="Currency To Buy"
            setQueryData={setSellData}
            direction={"fromCurrency"}
            flag={"fromFlag"}
          />
        </div>
        <div className="input-container last">
          <CurrencyDropdown
            queryData={sellData}
            requestList={countryCurrency}
            placeholder="Currency To Pay With"
            setQueryData={setSellData}
            direction={"toCurrency"}
            flag={"toFlag"}
          />
        </div>

        <div className="input-container last">
          <BasicTextField
            name="amount"
            onChange={handleChangeSell}
            type="number"
            label="Amount To Buy"
            value={sellData?.amount}
          />
        </div>
        <div className="input-container last">
          <BasicTextField
            name="rate"
            onChange={handleChangeSell}
            type="number"
            label="Your Exchange Rate"
            value={sellData?.rate}
          />
        </div>
        <div className="input-container last">
          <BasicTextField
            name="minimumAmount"
            onChange={handleChangeSell}
            type="number"
            label="Minimum Amount To Buy"
            value={sellData?.minimumAmount}
          />
        </div>
        <div className="input-container last">
          <BasicTextField
            name="available"
            onChange={handleChangeSell}
            type="number"
            label="Avalable Amount To Buy"
            value={sellData?.available}
          />
        </div>
        <UseRadioGroup firstLabel="Buy in bits" secondLabel="Buy all at once" />
        <div className="form-button">
          <button className="primary">
            {loading ? "Loading..." : "Create Buy Request"}
          </button>
          <div className={style.seperator}>
            <span className={style.seperatorText}>or</span>
            <span className={style.seperatorDivider}></span>
          </div>
          <button className={style.buttonUnderline}>Buy Manually</button>
        </div>
      </form>
    </div>
  );
}
