import React, { useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import style from "../styling/styles.module.css";

export default function CurrencyDropdown(props) {
  const { requestList, queryData, setQueryData, direction, flag } = props;

  const countries = requestList?.map((country) => country?.[direction]?.iso2);
  const countriesUnique = countries?.filter((c, index) => {
    return countries?.indexOf(c) === index;
  });

  const handleSelectCountry = (code) => {
    let arr = [];
    arr = requestList?.filter((country) => country?.[direction]?.iso2 === code);
    return arr[0]?.[direction];
  };

  return (
    <>
      <p className={style.dropdownLabel}>{props.placeholder}</p>
      <ReactFlagsSelect
        placeholder={props.placeholder}
        className={style.currencyDropdown}
        selected={queryData?.[flag]}
        onSelect={(code) =>
          setQueryData({
            ...queryData,
            [direction]: handleSelectCountry(code, "from").currency,
            [flag]: handleSelectCountry(code, "from").iso2,
          })
        }
        countries={countriesUnique}
        customLabels={{
          US: "USD",
          GB: "GBP",
          NG: "NGN",
        }}
        // customLabels={{
        //   US: "US USD",
        //   GB: "Pound GBP",
        //   NG: "Nigerian NGN",
        // }}
        defaultCountry="US"
      />
    </>
  );
}
