import React, { useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import * as Walletservice from "../../services/wallet";
import * as TransactionService from "../../services/transaction";

function TransactionProvider() {
  const { addToast } = useToasts();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errMsg, setrErrMsg] = useState("");
  const history = useHistory();

  const [transactions, setTransactions] = useState([]);
  const [transactionFilter, setTransactionFilter] = useState({
    requestType: "SELL",
    amount: "",

    fromCurrency: "NGN",
    fromFlag: "NG",
    walletId: "",
    status: "SUCCESS",
  });
  const [walletList, setWalletLists] = useState([]);
  const [tranferData, setTransferData] = useState({
    selectedWallet: "",
    amount: "",
    receiverWalletId: "",
  });
  const [withdraw, setWithdraw] = useState({
    accountNo: "",
    amount: "",
    narration: "",
    bankCode: "",
    sortCode: "",
    selectedWallet: {},
  });

  const handleTransactionFilter = (e) => {
    const { name, value } = e.target;
    setTransactionFilter({ ...transactionFilter, [name]: value });
  };

  const handleChangeTransfer = (e) => {
    const { name, value } = e.target;
    setTransferData({ ...tranferData, [name]: value });
  };

  const handleChangeWithdraw = (e) => {
    const { name, value } = e.target;
    setWithdraw({ ...withdraw, [name]: value });
  };

  const getWallets = () => {
    setLoading(true);
    Walletservice.listWallets()
      .then((res) => {
        setLoading(false);
        setError(false);
        const walletId = res.data?.[0]?._id;
        setTransactionFilter({ ...transactionFilter, walletId });
        setWalletLists([...res.data]);
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response.data.error === "TOKEN_EXPIRED") {
          history.push("/sign-in");
        }
        setLoading(false);
        setError(true);
        setrErrMsg(
          err.response
            ? err.response.data.message
            : err.message || "Check your Internet connection"
        );
        addToast(
          err.response
            ? err.response.data.message
            : err.message || "Check your Internet connection",
          { appearance: "error" }
        );

        setTimeout(() => {
          setError(false);
        }, 3000);
      });
  };
  useEffect(() => {
    getWallets();
  }, []);

  const getMyTransactions = () => {
    setLoading(true);
    TransactionService.getMyTransaction(transactionFilter)
      .then((res) => {
        setLoading(false);
        setError(false);
        setTransactions([...res?.data?.transactions]);
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response.data.error === "TOKEN_EXPIRED") {
          history.push("/sign-in");
        }
        setLoading(false);
        setError(true);
        setrErrMsg(
          err.response
            ? err.response.data.message
            : err.message || "Check your Internet connection"
        );
        addToast(
          err.response
            ? err.response.data.message
            : err.message || "Check your Internet connection",
          { appearance: "error" }
        );

        setTimeout(() => {
          setError(false);
        }, 3000);
      });
  };

  const transferToWallet = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {};
    data.narration = "Monirates payment";

    data.amount = Number(tranferData.amount);
    data.walletId = tranferData.receiverWalletId;
    const walletId = tranferData?.selectedWallet?._id;
    TransactionService.getTransactionKey().then((res) => {
      const transactionKey = res?.data?.key;
      TransactionService.transferToWallet(walletId, data, transactionKey)
        .then((res) => {
          addToast(res?.message, { appearance: "success" });
          setLoading(false);
          setError(false);
        })
        .catch((err) => {
          console.log(err.response);
          if (err.response.data.error === "TOKEN_EXPIRED") {
            history.push("/sign-in");
          }
          setLoading(false);
          setError(true);
          setrErrMsg(
            err.response
              ? err.response.data.message
              : err.message || "Check your Internet connection"
          );

          addToast(
            err.response
              ? err.response.data.message
              : err.message || "Check your Internet connection",
            { appearance: "error" }
          );

          setTimeout(() => {
            setError(false);
          }, 3000);
        });
    });
  };

  const transferToBank = (e) => {
    e.preventDefault();
    setLoading(true);

    const data = {};
    data.accountNo = withdraw.accountNo;
    data.amount = Number(withdraw.amount);
    data.narration = withdraw.narration;
    data.bankCode = withdraw.bankCode;
    data.sortCode = withdraw.sortCode;

    const walletId = withdraw?.selectedWallet?._id;
    TransactionService.getTransactionKey()
      .then((res) => {
        const transactionKey = res?.data?.key;
        data.transactionKey = transactionKey;
        TransactionService.transferToBank(walletId, data)
          .then((res) => {
            addToast(res?.message, { appearance: "success" });
            setLoading(false);
            setError(false);
          })
          .catch((err) => {
            console.log(err.response);
            if (err.response.data.error === "TOKEN_EXPIRED") {
              history.push("/sign-in");
            }
            setLoading(false);
            setError(true);
            setrErrMsg(
              err.response
                ? err.response.data.message
                : err.message || "Check your Internet connection"
            );
            addToast(
              err.response
                ? err.response.data.message
                : err.message || "Check your Internet connection",
              { appearance: "error" }
            );

            setTimeout(() => {
              setError(false);
            }, 3000);
          });
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response.data.error === "TOKEN_EXPIRED") {
          history.push("/sign-in");
        }
        setLoading(false);
        setError(true);
        setrErrMsg(
          err.response
            ? err.response.data.message
            : err.message || "Check your Internet connection"
        );
        addToast(
          err.response
            ? err.response.data.message
            : err.message || "Check your Internet connection",
          { appearance: "error" }
        );

        setTimeout(() => {
          setError(false);
        }, 3000);
      });
  };
  useEffect(() => {
    if (transactionFilter.walletId) {
      getMyTransactions();
    }
  }, [transactionFilter]);

  useEffect(() => {
    const walletId = walletList.find(
      (wallet) => wallet.currency.currency === transactionFilter.fromCurrency
    )?._id;

    setTransactionFilter({ ...transactionFilter, walletId });
  }, [transactionFilter.fromCurrency]);

  return {
    transactionFilter,
    transactions,
    tranferData,
    walletList,
    withdraw,
    loading,
    setTransactionFilter,
    handleTransactionFilter,
    setTransferData,
    setWithdraw,
    handleChangeTransfer,
    handleChangeWithdraw,
    transferToWallet,
    transferToBank,
  };
}

export default TransactionProvider;
