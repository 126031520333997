import { WALLET_LIST } from "../../types/types";
import * as WalletService from "../../../services/wallet";

export function wallet() {
  const userWallets = (walletList) => {
    return {
      type: WALLET_LIST,
      payload: {
        walletList,
      },
    };
  };

  return (dispatch) => {
    return WalletService.listWallets().then((res) => {
      const walletList = res.data;
      dispatch(userWallets(walletList));
      return res;
    });
  };
}
export default wallet;
