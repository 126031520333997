import axios from "./axiosInstance";
import { transaction } from "./../../store/actions/transaction/transaction";

export const tokenConfig = (transactionKey = "") => {
  const token = localStorage.getItem("monirate_tk");
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  if (transactionKey) {
    config.headers["transactionKey"] = transactionKey;
  }
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  } else {
    delete config.headers["Authorization"];
  }
  return config;
};

export const GET = (endpoint) => {
  return axios.get(`${endpoint}`, tokenConfig());
};
export const POST = (endpoint, data, transactionKey = "") => {
  return axios.post(`${endpoint}`, data, tokenConfig(transactionKey));
};

export const PUT = (endpoint, data) => {
  return axios.put(`${endpoint}`, data, tokenConfig());
};

export const PATCH = (endpoint, data) => {
  return axios.patch(`${endpoint}`, data, tokenConfig());
};

export const DELETE = (endpoint) => {
  return axios.delete(`${endpoint}`, tokenConfig());
};
