import _ from "lodash";
import { GET, PUT, POST, PATCH, DELETE } from "../core/config/Api";

export const createRequest = (data) => {
  return POST(`trade`, data).then((res) => {
    return res?.data;
  });
};

export const buyCurrency = (id, data) => {
  return POST(`trade/${id}/buy`, data).then((res) => {
    return res?.data;
  });
};

export const sellCurrency = (id, data) => {
  return POST(`trade/${id}/sell`, data).then((res) => {
    return res?.data;
  });
};

export const listOfRequests = (data) => {
  return GET(
    `trades/${data?.requestType}?fromCurrency=NGN&toCurrency=GBP`
  ).then((res) => {
    return res?.data;
  });
};

export const listOfOffers = (data) => {
  // `trades/${data?.requestType}?fromCurrency=${data.fromCurrency}&toCurrency=${data.toCurrency}&rate=${data.rate}&minimumAmount=${data.minimumAmount}`
  return GET(
    `trades/${data?.requestType}?fromCurrency=${data.fromCurrency}&toCurrency=${data.toCurrency}`
  ).then((res) => {
    return res?.data;
  });
};

export const viewOffers = (data) => {
  return GET(`trades/SELL?fromCurrency=${data}&toCurrency=GBP`).then((res) => {
    return res?.data;
  });
};

export const viewRequest = (id) => {
  return GET(`trade/${id}`).then((res) => {
    return res?.data;
  });
};
