import Moment from "react-moment";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import UseRadioGroup from "../../components/RadioGroup";
import BasicTextField from "../../components/BasicTextField";
import CurrencyDropdown from "../../components/CurrencyDropdown";
import style from "../../styling/styles.module.css";
import "../../styling/styles.css";
import RequestProvider from "./RequestProvider";
import LoadingIcon from "./../../components/LoadingIcon";

export default function MyRequest() {
  const {
    handleChange,
    handleRequestFilter,
    setQueryData,
    queryData,
    requestList,
    requestFilter,
    loading,
  } = RequestProvider();

  return (
    <div className={style.listWrap}>
      <div className={style.listWrapFilter}>
        <div className="offer-type">
          <h4>Transaction To See</h4>
          <div className="radio-button">
            <UseRadioGroup
              handleChange={handleRequestFilter}
              requestType={requestFilter.requestType}
              firstLabel="SELL"
              secondLabel="BUY"
            />
          </div>
        </div>
        <div className="minimum-amount">
          <h4>Transaction Amount</h4>
          <div className="minimum-amount-input">
            <BasicTextField label="5000" />
          </div>
        </div>
      </div>
      <div className={style.listWrapAvailableOffers}>
        <div className={style.listWrapOffer}>
          {requestList?.length > 0 &&
            requestList.map((item, index) => (
              <ul key={index}>
                <li>
                  <h3>{`${item.type} Currency`}</h3>
                  <p>Request Type</p>
                </li>
                <li>
                  <h3>{`${item.initialAmount}`}</h3>
                  <p>Amount</p>
                </li>
                <li>
                  <h3>{`${item?.fromCurrency?.currency} ${item?.fromCurrency?.currencyName}`}</h3>
                  <p>Currency</p>
                </li>
                <br />
                <br />
                <br />
                <li>
                  <h3>
                    {" "}
                    <Moment format="DD/MM/YYYY">{item?.createdAt}</Moment>
                  </h3>
                  <p>Date</p>
                </li>
                <li>
                  <h3>
                    {" "}
                    <Moment format="HH:mm">{item?.createdAt}</Moment>
                  </h3>
                  <p>Time</p>
                </li>
                <li>
                  <span
                    className={`status ${
                      item.status === "OPEN" ? "pending" : "success"
                    }`}
                  >
                    {item?.status}
                  </span>
                </li>
                <li>
                  <button className={style.buttonTertiary}>
                    Cancel Request
                  </button>
                </li>
              </ul>
            ))}
        </div>
        {loading ? (
          <div className={style.centerLoader}>
            <ul>
              <li>
                <p>
                  <LoadingIcon />
                </p>
              </li>
            </ul>
          </div>
        ) : (
          requestList.length === 0 && (
            <div className={style.listWrapOffer}>
              <ul>
                <li>
                  <p>Your request list is empty !</p>
                </li>
              </ul>
            </div>
          )
        )}
      </div>
    </div>
  );
}
