import {
  FormGroup,
  FormControl,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";

import style from "../styling/styles.module.css";
import WalletProvider from "./../pages/wallet/provider";

export default function SelectCurrency() {
  const gray = "#9DA0B8";
  const pink = "#FF2E63";
  const {
    supportedCountries,
    walletCurrency,
    loading,
    handleSelectCurrency,
    addWallet,
  } = WalletProvider();

  return (
    <div className={style.wrapContainerSmaller}>
      <FormGroup className={style.borderForm}>
        <h4>Select Wallet Currency</h4>
        {supportedCountries?.map((country) => (
          <FormControlLabel
            control={
              <Checkbox
                name="currency"
                checked={country.currency === walletCurrency.currency}
                onChange={(e) => handleSelectCurrency(e, country)}
                sx={{
                  color: gray,
                  "& .MuiSvgIcon-root": { fontSize: 32 },
                  "&.Mui-checked": {
                    color: pink,
                  },
                }}
              />
            }
            label={
              <Typography variant="h6" color="textSecondary">
                {`${country?.countryName} ${country?.currencyName} (${country?.currency})`}
              </Typography>
            }
          />
        ))}

        <button onClick={addWallet} className="primary">
          {loading ? "Loading..." : "Create Wallet"}
        </button>
      </FormGroup>
    </div>
  );
}
