import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
} from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import style from "./styling/styles.module.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// Components Import

import DashboardLayout from "./components/DashboardLayout";
import WelcomeMessage from "./components/WelcomeMessage";
import SelectCurrency from "./components/SelectCurrency";
import ActionCarousel from "./components/CurrencyCarousel";
import Carousel from "./components/ActionCarousel";
import StickyHeadTable from "./components/Table";
import ProfileHint from "./components/ProfileHint";
import ProfileToolTip from "./components/ProfileToolTip";

// Pages Import

import Withdraw from "./pages/transaction/MakeWithdraw";
import ConfirmBankDetails from "./pages/transaction/WithdrawConfirmation";
import MyWallet from "./pages/wallet/MyWallet";
import Offers from "./pages/request/Offers";
import MyTransaction from "./pages/transaction/MyTransaction";
import SignUp from "./pages/auth/signUp/SignUp";
import VerifyOtp from "./pages/auth/verifyOtp/VerifyOtp";
import SignIn from "./pages/auth/signIn/SignIn";
import ForgotPassword from "./pages/auth/forgotPassword/ForgotPassword";
import PasswordResetLink from "./pages/auth/PasswordResetLinkProvider/PasswordResetLink";
import ResetPassword from "./pages/auth/resetPassword/ResetPassword";
import ChangeEmail from "./pages/auth/changeEmail/ChangeEmail";
import ChangePassword from "./pages/auth/changePassword/ChangePassword";
import PasswordCreated from "./pages/auth/PasswordCreated";
import BuyRequest from "./pages/request/BuyRequest";
import SellRequest from "./pages/request/SellRequest";
import FundWallet from "./pages/transaction/FundWallet";
import MakeTransfer from "./pages/transaction/MakeTransfer";
import TransferConfirmation from "./pages/transaction/TransferConfirmation";
import MyProfile from "./pages/auth/myProfile/MyProfile";
import MyRequest from "./pages/request/MyRequest";
import NairaWallet from "./pages/wallet/NairaWallet";
import CurrencyWallet from "./pages/wallet/CurrencyWallet";
import WithdrawSuccess from "./pages/transaction/WithdrawSuccess";

const theme = createTheme({
  typography: {
    fontFamily: "Inter",
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightBold: 700,
  },
  palette: {
    type: "light",
    primary: {
      main: "#ff2e63",
      light: "#192259",
      dark: "#0e164d",
    },
    secondary: {
      main: "#f50057",
      light: "rgba(247,51,120,0)",
    },
    text: {
      primary: "#0E164D",
      secondary: "#0E164D",
      disabled: "#9DA0B8",
      hint: "#FF2E63",
    },
    divider: "#9DA0B8",
  },
  typography: {
    fontFamily: "inter",
  },
});

export default function App() {
  return (
    <ToastProvider autoDismiss>
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route path="/welcome">
              <DashboardLayout>
                <Breadcrumbs
                  aria-label="breadcrumb"
                  className={style.breadCrumbNavigation}
                >
                  <Typography>Welcome</Typography>
                </Breadcrumbs>
                <WelcomeMessage />
                <SelectCurrency />
              </DashboardLayout>
            </Route>
            <Route exact path="/">
              <DashboardLayout>
                <Breadcrumbs
                  aria-label="breadcrumb"
                  className={style.breadCrumbNavigation}
                >
                  <Typography>Home</Typography>
                </Breadcrumbs>
                <ProfileHint />
                <ActionCarousel />
                <Carousel />
                <StickyHeadTable />
                <ProfileToolTip />
              </DashboardLayout>
            </Route>
            <Route path="/add-wallet">
              <DashboardLayout>
                <Breadcrumbs
                  aria-label="breadcrumb"
                  className={style.breadCrumbNavigation}
                >
                  <NavLink underline="hover" exact to="/">
                    Home
                  </NavLink>
                  <Typography>Add Wallet</Typography>
                </Breadcrumbs>
                <SelectCurrency />
              </DashboardLayout>
            </Route>
            <Route path="/my-profile">
              <DashboardLayout>
                <Breadcrumbs
                  aria-label="breadcrumb"
                  className={style.breadCrumbNavigation}
                >
                  <NavLink underline="hover" exact to="/">
                    Home
                  </NavLink>
                  <Typography>My Profile</Typography>
                </Breadcrumbs>
                <MyProfile />
              </DashboardLayout>
            </Route>
            <Route path="/transfer-confirmation">
              <DashboardLayout>
                <TransferConfirmation />
              </DashboardLayout>
            </Route>
            <Route path="/make-transfer">
              <DashboardLayout>
                <Breadcrumbs
                  aria-label="breadcrumb"
                  className={style.breadCrumbNavigation}
                >
                  <NavLink underline="hover" exact to="/">
                    Home
                  </NavLink>
                  <Typography>Make Transfer</Typography>
                </Breadcrumbs>
                <MakeTransfer />
              </DashboardLayout>
            </Route>
            <Route path="/make-withdraw">
              <DashboardLayout>
                <Breadcrumbs
                  aria-label="breadcrumb"
                  className={style.breadCrumbNavigation}
                >
                  <NavLink underline="hover" exact to="/">
                    Home
                  </NavLink>
                  <Typography>Withdraw</Typography>
                </Breadcrumbs>
                <Withdraw />
              </DashboardLayout>
            </Route>
            <Route path="/withdraw-successful">
              <DashboardLayout>
                <Breadcrumbs
                  aria-label="breadcrumb"
                  className={style.breadCrumbNavigation}
                >
                  <NavLink underline="hover" exact to="/">
                    Home
                  </NavLink>
                  <Typography>Withdraw Successful</Typography>
                </Breadcrumbs>
                <WithdrawSuccess />
                <ActionCarousel />
                <Carousel />
                <StickyHeadTable />
              </DashboardLayout>
            </Route>
            <Route path="/fund-wallet">
              <DashboardLayout>
                <Breadcrumbs
                  aria-label="breadcrumb"
                  className={style.breadCrumbNavigation}
                >
                  <NavLink underline="hover" exact to="/">
                    Home
                  </NavLink>
                  <Typography>Fund Wallet</Typography>
                </Breadcrumbs>
                <FundWallet />
              </DashboardLayout>
            </Route>
            <Route path="/confirm-bank-details">
              <DashboardLayout>
                <Breadcrumbs
                  aria-label="breadcrumb"
                  className={style.breadCrumbNavigation}
                >
                  <NavLink underline="hover" exact to="/">
                    Home
                  </NavLink>
                  <Link underline="hover" href="/">
                    Make Withdraw
                  </Link>
                  <Typography>Confirm Details</Typography>
                </Breadcrumbs>
                <ConfirmBankDetails />
              </DashboardLayout>
            </Route>
            <Route path="/my-wallet">
              <DashboardLayout>
                <Breadcrumbs
                  aria-label="breadcrumb"
                  className={style.breadCrumbNavigation}
                >
                  <NavLink underline="hover" exact to="/">
                    Home
                  </NavLink>
                  <Typography>My Wallet</Typography>
                </Breadcrumbs>
                <MyWallet />
              </DashboardLayout>
            </Route>
            <Route path="/naira-wallet">
              <DashboardLayout>
                <Breadcrumbs
                  aria-label="breadcrumb"
                  className={style.breadCrumbNavigation}
                >
                  <NavLink underline="hover" exact to="/">
                    Home
                  </NavLink>
                  <Link underline="hover" to="/my-wallet">
                    My Wallet
                  </Link>
                  <Typography>Naira Wallet</Typography>
                </Breadcrumbs>
                <NairaWallet />
                <Carousel />
                <StickyHeadTable />
              </DashboardLayout>
            </Route>
            <Route path="/currency-wallet">
              <DashboardLayout>
                <Breadcrumbs
                  aria-label="breadcrumb"
                  className={style.breadCrumbNavigation}
                >
                  <NavLink underline="hover" exact to="/">
                    Home
                  </NavLink>
                  <Link underline="hover" to="/my-wallet">
                    My Wallet
                  </Link>
                  <Typography>Wallet</Typography>
                </Breadcrumbs>
                <CurrencyWallet />
                <Carousel />
                <StickyHeadTable />
              </DashboardLayout>
            </Route>
            <Route path="/buy-request">
              <DashboardLayout>
                <Breadcrumbs
                  aria-label="breadcrumb"
                  className={style.breadCrumbNavigation}
                >
                  <NavLink underline="hover" exact to="/">
                    Home
                  </NavLink>
                  <Typography>Buy Request</Typography>
                </Breadcrumbs>
                <BuyRequest />
              </DashboardLayout>
            </Route>
            <Route path="/sell-request">
              <DashboardLayout>
                <Breadcrumbs
                  aria-label="breadcrumb"
                  className={style.breadCrumbNavigation}
                >
                  <NavLink underline="hover" exact to="/">
                    Home
                  </NavLink>
                  <Typography>Sell Request</Typography>
                </Breadcrumbs>
                <SellRequest />
              </DashboardLayout>
            </Route>
            <Route path="/my-request">
              <DashboardLayout>
                <Breadcrumbs
                  aria-label="breadcrumb"
                  className={style.breadCrumbNavigation}
                >
                  <NavLink underline="hover" exact to="/">
                    Home
                  </NavLink>
                  <Typography>My Request</Typography>
                </Breadcrumbs>
                <MyRequest />
              </DashboardLayout>
            </Route>
            <Route path="/offers">
              <DashboardLayout>
                <Breadcrumbs
                  aria-label="breadcrumb"
                  className={style.breadCrumbNavigation}
                >
                  <NavLink underline="hover" to="/">
                    Home
                  </NavLink>
                  <Typography>Offers</Typography>
                </Breadcrumbs>
                <Offers />
              </DashboardLayout>
            </Route>
            <Route path="/my-transaction">
              <DashboardLayout>
                <Breadcrumbs
                  aria-label="breadcrumb"
                  className={style.breadCrumbNavigation}
                >
                  <NavLink underline="hover" to="/">
                    Home
                  </NavLink>
                  <Typography>Transaction</Typography>
                </Breadcrumbs>
                <MyTransaction />
              </DashboardLayout>
            </Route>

            <Route path="/sign-up">
              <SignUp />
            </Route>
            <Route path="/verify-otp">
              <VerifyOtp />
            </Route>
            <Route path="/sign-in">
              <SignIn />
            </Route>
            <Route path="/forgot-password">
              <ForgotPassword />
            </Route>
            <Route path="/password-reset">
              <PasswordResetLink />
            </Route>
            <Route path="/reset-password">
              <ResetPassword />
            </Route>
            <Route path="/change-email">
              <ChangeEmail />
            </Route>
            <Route path="/change-password">
              <ChangePassword />
            </Route>
            <Route path="/password-created">
              <PasswordCreated />
            </Route>
            <Route path="*">
              <SignIn />
            </Route>
          </Switch>
        </Router>
      </ThemeProvider>
    </ToastProvider>
  );
}
