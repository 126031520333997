import { useState } from "react";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import { validateEmail } from "../../../utils/credentialsValidator";
import * as AuthServices from "../../../services/auth";

export const VerifyOtpProvider = () => {
  const { addToast } = useToasts();
  const [credentials, setCredentials] = useState({
    email: "",
    otp: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errMsg, setrErrMsg] = useState("");
  const history = useHistory();

  const handleChange = (e) => {
    const { id, value } = e.target;
    setCredentials({ ...credentials, [id]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    // Validation will go here
    const data = {};
    if (validateEmail(credentials.email)) {
      data.email = credentials.email;
    } else {
      setLoading(false);
      addToast("Please, enter valid Email!", { appearance: "error" });
      return;
    }

    if (credentials.otp) {
      data.otp = credentials.otp;
    } else {
      addToast("Please, enter valid OTP!", { appearance: "error" });
      setLoading(false);
      return;
    }
    AuthServices.verifyOtp(data)
      .then((res) => {
        setLoading(false);
        setError(false);
        addToast(res?.message, { appearance: "success" });
        setCredentials({
          otp: "",
          email: "",
        });
        history.push("/sign-in");
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
        setrErrMsg(
          err.response
            ? err.response.data.message
            : err.message || "Check your Internet connection"
        );
        addToast(
          err.response
            ? err.response.data.message
            : err.message || "Check your Internet connection",
          { appearance: "error" }
        );

        setTimeout(() => {
          setError(false);
        }, 3000);
      });
  };

  return {
    credentials,
    loading,
    errMsg,
    error,
    handleSubmit,
    handleChange,
  };
};
