import { WALLET_LIST } from "./../../types/types";
const initialStateWallet = {
  walletList: [],
};

function walletReducer(state = initialStateWallet, action) {
  switch (action.type) {
    case WALLET_LIST:
      return {
        walletList: action.payload.walletList,
        status: action.payload.status,
      };

    default:
      return state;
  }
}

export default walletReducer;
