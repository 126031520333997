import React, { useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import * as Walletservice from "../../services/wallet";
import * as TransactionService from "../../services/transaction";

function WalletProvider() {
  const { addToast } = useToasts();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errMsg, setrErrMsg] = useState("");
  const history = useHistory();

  const [supportedCountries, setSupportedCountries] = useState([]);
  const [walletList, setWalletLists] = useState([]);
  const [walletCurrency, setWalletCurrency] = useState({
    currency: "",
  });

  const [fundWalletData, setFundWalletData] = useState({
    amount: "",
    selectedWallet: {},
  });

  const handleChangeFund = (e) => {
    const { name, value } = e.target;
    setFundWalletData({ ...fundWalletData, [name]: value });
  };

  const handleSelectCurrency = (e, country) => {
    setWalletCurrency({ currency: country.currency });
  };

  const getSupportedCountries = () => {
    setLoading(true);
    Walletservice.supportedCountries()
      .then((res) => {
        setLoading(false);
        setError(false);
        setSupportedCountries([...res.data]);
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response.data.error === "TOKEN_EXPIRED") {
          history.push("/sign-in");
        }
        setLoading(false);
        setError(true);
        setrErrMsg(
          err.response
            ? err.response.data.message
            : err.message || "Check your Internet connection"
        );
        addToast(
          err.response
            ? err.response.data.message
            : err.message || "Check your Internet connection",
          { appearance: "error" }
        );

        setTimeout(() => {
          setError(false);
        }, 3000);
      });
  };
  const defaultWalletId = walletList?.[0?._id];
  const fundWallet = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {};
    data.amount = fundWalletData.amount;

    TransactionService.getTransactionKey()
      .then((res) => {
        const walletId = fundWalletData?.selectedWallet?._id;
        const transactionKey = res?.data?.key;
        data.transactionKey = transactionKey;

        TransactionService.fundWalletTest(walletId, data)
          .then((res) => {
            setLoading(false);
            setError(false);

            addToast(res?.message, { appearance: "success" });
            history.push("/my-wallet");
          })
          .catch((err) => {
            console.log(err.response);
            if (err.response.data.error === "TOKEN_EXPIRED") {
              history.push("/sign-in");
            }
            setLoading(false);
            setError(true);
            setrErrMsg(
              err.response
                ? err.response.data.message
                : err.message || "Check your Internet connection"
            );
            addToast(
              err.response
                ? err.response.data.message
                : err.message || "Check your Internet connection",
              { appearance: "error" }
            );

            setTimeout(() => {
              setError(false);
            }, 3000);
          });
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response.data.error === "TOKEN_EXPIRED") {
          history.push("/sign-in");
        }
        setLoading(false);
        setError(true);
        setrErrMsg(
          err.response
            ? err.response.data.message
            : err.message || "Check your Internet connection"
        );
        addToast(
          err.response
            ? err.response.data.message
            : err.message || "Check your Internet connection",
          { appearance: "error" }
        );

        setTimeout(() => {
          setError(false);
        }, 3000);
      });
  };

  const getWallets = () => {
    setLoading(true);
    Walletservice.listWallets()
      .then((res) => {
        setLoading(false);
        setError(false);
        setWalletLists([...res.data]);
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response.data.error === "TOKEN_EXPIRED") {
          history.push("/sign-in");
        }
        setLoading(false);
        setError(true);
        setrErrMsg(
          err.response
            ? err.response.data.message
            : err.message || "Check your Internet connection"
        );
        addToast(
          err.response
            ? err.response.data.message
            : err.message || "Check your Internet connection",
          { appearance: "error" }
        );

        setTimeout(() => {
          setError(false);
        }, 3000);
      });
  };
  useEffect(() => {
    getSupportedCountries();
    getWallets();
  }, []);

  const addWallet = () => {
    setLoading(true);
    Walletservice.createWallet(walletCurrency)
      .then((res) => {
        addToast(res?.message, { appearance: "success" });
        setLoading(false);
        setError(false);
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response.data.error === "TOKEN_EXPIRED") {
          history.push("/sign-in");
        }
        setLoading(false);
        setError(true);
        setrErrMsg(
          err.response
            ? err.response.data.message
            : err.message || "Check your Internet connection"
        );
        addToast(
          err.response
            ? err.response.data.message
            : err.message || "Check your Internet connection",
          { appearance: "error" }
        );

        setTimeout(() => {
          setError(false);
        }, 3000);
      });
  };
  return {
    supportedCountries,
    walletCurrency,
    walletList,
    fundWalletData,
    defaultWalletId,
    loading,
    handleSelectCurrency,
    handleChangeFund,
    addWallet,
    fundWallet,
    setFundWalletData,
  };
}

export default WalletProvider;
