import Moment from "react-moment";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import UseRadioGroup from "../../components/RadioGroup";
import BasicTextField from "../../components/BasicTextField";
import CurrencyDropdown from "../../components/CurrencyDropdown";
import style from "../../styling/styles.module.css";
import "../../styling/styles.css";
import TransactionModal from "../../components/TransactionModal";
import { countryCurrency } from "../../utils/countryCurrencyList";
import TransactionProvider from "./TransactionProvider";
import LoadingIcon from "./../../components/LoadingIcon";

export default function MyTransaction() {
  const {
    transactionFilter,
    transactions,
    loading,
    setTransactionFilter,
    handleTransactionFilter,
  } = TransactionProvider();

  return (
    <div className={style.listWrap}>
      <div className={style.listWrapFilter}>
        <div className="currency">
          <h4>Currency</h4>
          <div className="currency-select">
            <CurrencyDropdown
              queryData={transactionFilter}
              requestList={countryCurrency}
              placeholder="Select Currency"
              setQueryData={setTransactionFilter}
              direction={"fromCurrency"}
              flag={"fromFlag"}
            />
          </div>
        </div>
        <div className="offer-type">
          <h4>Transaction To See</h4>
          <div className="radio-button">
            <UseRadioGroup
              handleChange={handleTransactionFilter}
              requestType={transactionFilter.requestType}
              firstLabel="Buy Transaction"
              secondLabel="Sell Transaction"
            />
          </div>
        </div>
        <div className="minimum-amount">
          <h4>Transaction Amount</h4>
          <div className="minimum-amount-input">
            <BasicTextField
              name="amount"
              onChange={handleTransactionFilter}
              type="number"
              label="Amount"
              value={transactionFilter.amount}
            />
          </div>
        </div>
      </div>

      <div className={style.listWrapAvailableOffers}>
        {loading ? (
          <div className={style.centerLoader}>
            <ul>
              <li>
                <p>
                  <LoadingIcon />
                </p>
              </li>
            </ul>
          </div>
        ) : (
          transactions.length === 0 && (
            <div className={style.listWrapOffer}>
              <ul>
                <li>
                  <p>Transaction list is empty !</p>
                </li>
              </ul>
            </div>
          )
        )}
        <div className={style.listWrapOffer}>
          {transactions?.map((transaction, index) => (
            <div key={index}>
              <ul>
                <li>
                  <h3>{transaction.type}</h3>
                  <p>Transaction Type</p>
                </li>
                <li>
                  <h3>{`${transaction.currency} ${transaction.amount}/`}</h3>
                  <p>Amount</p>
                </li>
                <li>
                  <h3>
                    <Moment format="DD/MM/YYYY">
                      {transaction?.createdAt}
                    </Moment>
                  </h3>
                  <p>Date</p>
                </li>
                <li>
                  <h3>
                    <Moment format="HH:mm">{transaction?.createdAt}</Moment>
                  </h3>
                  <p>Time</p>
                </li>
                <li>
                  <span
                    className={`status ${
                      transaction.status === "SUCCESS"
                        ? "success"
                        : transaction.status === "PENDING"
                        ? "pending"
                        : "failed"
                    }`}
                  >
                    {transaction?.status.toLowerCase()}
                  </span>
                </li>
                <li>
                  <TransactionModal transaction={transaction} label=">" />
                </li>
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
