import { TRANSACTION_LIST } from "../../types/types";

import * as TrasactionService from "../../../services/transaction";

export function transaction(data) {
  const userTransactions = (transactionList) => {
    return {
      type: TRANSACTION_LIST,
      payload: {
        transactionList,
      },
    };
  };

  return (dispatch) => {
    return TrasactionService.getMyTransaction(data).then((res) => {
      const transactionList = res.data.transactions;
      dispatch(userTransactions(transactionList));
      return res.data;
    });
  };
}
export default transaction;
