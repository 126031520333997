import { useState } from "react";
import { useToasts } from "react-toast-notifications";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as AuthServices from "../../../services/auth";
import { validateEmail } from "../../../utils/credentialsValidator";
import { setCurrentUser } from "../../../store/actions/auth/auth";

export const SignInProvider = (props) => {
  const { auth: authStore } = useSelector((state) => state.auth || {});
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errMsg, setrErrMsg] = useState("");
  const history = useHistory();

  const handleChange = (e) => {
    const { id, value } = e.target;
    setCredentials({ ...credentials, [id]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    // Validation will go here
    const data = {};
    if (validateEmail(credentials.email)) {
      data.email = credentials.email;
    } else {
      setLoading(false);
      addToast("Please, enter valid Email!", { appearance: "error" });
      return;
    }

    data.password = credentials.password;

    AuthServices.login(data)
      .then((res) => {
        setLoading(false);
        setError(false);

        const token = res?.data?.token;
        localStorage.setItem("monirate_tk", token);

        const user = res?.data;
        localStorage.setItem("monirate_user", JSON.stringify(user));

        dispatch(setCurrentUser(user));
        addToast(res?.message, { appearance: "success" });

        setCredentials({
          email: "",
          password: "",
        });

        history.push("/");
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response.data.error === "TOKEN_EXPIRED") {
          history.push("/sign-in");
        }
        setLoading(false);
        setError(true);
        setrErrMsg(
          err.response
            ? err.response.data.message
            : err.message || "Check your Internet connection"
        );
        addToast(
          err.response
            ? err.response.data.message
            : err.message || "Check your Internet connection",
          { appearance: "error" }
        );

        setTimeout(() => {
          setError(false);
        }, 3000);
      });
  };

  return {
    credentials,
    loading,
    errMsg,
    error,
    handleSubmit,
    handleChange,
  };
};
