import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
} from "react-router-dom";
import BasicTextField from "../../components/BasicTextField";
import makeTransferIllustration from "../../images/makeTransferIllustration.svg";
import WalletDropdown from "../../components/WalletDropdown";
import style from "../../styling/styles.module.css";
import TransactionProvider from "./TransactionProvider";

export default function MakeTransfer() {
  const {
    walletList,
    tranferData,
    loading,
    setTransferData,
    handleChangeTransfer,
    transferToWallet,
  } = TransactionProvider();
  return (
    <div className={style.wrapContainerBorder}>
      <span>
        <img
          className={style.illustrationImage}
          src={makeTransferIllustration}
        ></img>
      </span>
      {/* <div className={style.formSteps}>
        <ul>
          <li>
            <NavLink
              activeClassName={style.activeLink}
              exact
              to="/make-transfer"
            >
              Transfer Details
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName={style.activeLink}
              to="/transfer-confirmation"
            >
              Confirm Details
            </NavLink>
          </li>
        </ul>
      </div> */}
      <h4>Make Transfer</h4>
      <p>
        Make transfer using the Wallet ID of the user you wish to make transfer
        to.
      </p>
      <form className="withdraw-form" onSubmit={transferToWallet}>
        <div>
          <WalletDropdown
            placeholder="Wallet To Fund Transfer"
            walletList={walletList}
            fundWalletData={tranferData}
            setFundWalletData={setTransferData}
          />
        </div>
        <div className={style.inputContainer}>
          <BasicTextField
            label="Amount To Transfer"
            name="amount"
            onChange={handleChangeTransfer}
            type="number"
            value={tranferData.amount}
          />
        </div>
        <div className={style.inputContainerLast}>
          <BasicTextField
            label="Wallet ID"
            name="receiverWalletId"
            onChange={handleChangeTransfer}
            type="text"
            value={tranferData.receiverWalletId}
          />
        </div>
        <div className="form-button">
          <button className={style.buttonPrimary}>
            {loading ? "Loading..." : "Confirm Details"}
          </button>
          <div className={style.seperator}>
            <span className={style.seperatorText}>or</span>
            <span className={style.seperatorDivider}></span>
          </div>
          <button className={style.buttonUnderline}>Cancel Transfer</button>
        </div>
      </form>
    </div>
  );
}
