import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setCurrentUser } from "./store/actions/auth/auth";

const AuthProvider = (Component) => {
  const Authenticate = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    let user = localStorage.getItem("monirate_user");
    if (user) {
      user = JSON.parse(user);
    }

    const handleAuth = () => {
      const token = localStorage.getItem("monirate_tk");
      if (!token) {
        window.location.href = "/sign-in";
      }
    };

    handleAuth();

    const handleLogout = () => {
      localStorage.removeItem("monirate_tk");
      localStorage.removeItem("monirate_user");
      dispatch(setCurrentUser({ isAuthenticated: false, user: {} }));
      history.push("/sign-in");
    };

    return <Component {...props} handleLogout={handleLogout} user={user} />;
  };

  Authenticate.defaultProps = {
    Component,
  };
  return Authenticate;
};

export default AuthProvider;
