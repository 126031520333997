import React, { useState, useEffect, useCallback } from "react";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import * as ExchangeService from "../../services/exchange";

function RequestProvider() {
  const { addToast } = useToasts();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errMsg, setrErrMsg] = useState("");
  const history = useHistory();

  const [requestList, setRequestList] = useState([]);
  const [offerList, setOfferList] = useState([]);
  const [requestFilter, setRequestFilter] = useState({ requestType: "SELL" });
  const [offerFilter, setOfferFilter] = useState({
    requestType: "SELL",
    rate: "",
    minimumAmount: "",

    fromCurrency: "NGN",
    fromFlag: "NG",
    toCurrency: "GBP",
    toFlag: "GB",
  });

  const [queryData, setQueryData] = useState({
    requestType: "SELL",
    fromCurrency: "NGN",
    fromFlag: "NG",
    toCurrency: "GBP",
    toFlag: "GB",
  });
  const [sellData, setSellData] = useState({
    rate: "",
    minimumAmount: "",
    available: "",
    amount: "",

    requestType: "SELL",
    fromCurrency: "NGN",
    fromFlag: "NG",
    toCurrency: "GBP",
    toFlag: "GB",
  });

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setQueryData({ ...queryData, [name]: value });
  };

  const handleRequestFilter = (e) => {
    const { name, value } = e.target;
    setRequestFilter({ ...requestFilter, [name]: value });
  };

  const handleOfferFilter = (e) => {
    const { name, value } = e.target;
    setOfferFilter({ ...offerFilter, [name]: value });
  };

  const handleChangeSell = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setSellData({ ...sellData, [name]: value });
  };

  const getListOfRequests = useCallback(() => {
    setLoading(true);
    ExchangeService.listOfRequests(requestFilter)
      .then((res) => {
        setLoading(false);
        setError(false);
        setRequestList([...res?.data?.trades]);
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response.data.error === "TOKEN_EXPIRED") {
          history.push("/sign-in");
        }
        setLoading(false);
        setError(true);
        setrErrMsg(
          err.response
            ? err.response.data.message
            : err.message || "Check your Internet connection"
        );
        addToast(
          err.response
            ? err.response.data.message
            : err.message || "Check your Internet connection",
          { appearance: "error" }
        );

        setTimeout(() => {
          setError(false);
        }, 3000);
      });
  }, [requestFilter]);

  const getListOfOffers = useCallback(() => {
    setLoading(true);
    ExchangeService.listOfOffers(offerFilter)
      .then((res) => {
        setLoading(false);
        setError(false);
        setOfferList([...res?.data?.trades]);
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response.data.error === "TOKEN_EXPIRED") {
          history.push("/sign-in");
        }
        setLoading(false);
        setError(true);
        setrErrMsg(
          err.response
            ? err.response.data.message
            : err.message || "Check your Internet connection"
        );
        addToast(
          err.response
            ? err.response.data.message
            : err.message || "Check your Internet connection",
          { appearance: "error" }
        );

        setTimeout(() => {
          setError(false);
        }, 3000);
      });
  }, [offerFilter]);

  const sellCurrency = (e, type) => {
    e.preventDefault();
    const data = {};
    data.type = type;
    data.fromCurrency = sellData.fromCurrency;
    data.toCurrency = sellData.toCurrency;
    data.rate = Number(sellData.rate);
    data.minimumAmount = Number(sellData.minimumAmount);
    data.available = Number(sellData.available);

    setLoading(true);
    const exchangeType =
      data.type === "BUY"
        ? ExchangeService.sellCurrency
        : ExchangeService.buyCurrency;
    ExchangeService.createRequest(data)
      .then((res) => {
        const id = res?.data?._id;
        const amount = Number(sellData.amount);
        exchangeType(id, { amount })
          .then((res) => {
            addToast(res?.message, { appearance: "success" });
            setLoading(false);
            setError(false);
            setSellData({
              rate: "",
              minimumAmount: "",
              available: "",
              amount: "",

              requestType: "SELL",
              fromCurrency: "NGN",
              fromFlag: "NG",
              toCurrency: "GBP",
              toFlag: "GB",
            });
            history.push("/my-request");
          })
          .catch((err) => {
            console.log(err.response);
            if (err.response.data.error === "TOKEN_EXPIRED") {
              history.push("/sign-in");
            }
            setLoading(false);
            setError(true);
            setrErrMsg(
              err.response
                ? err.response.data.message
                : err.message || "Check your Internet connection"
            );
            addToast(
              err.response
                ? err.response.data.message
                : err.message || "Check your Internet connection",
              { appearance: "error" }
            );

            setTimeout(() => {
              setError(false);
            }, 3000);
          });
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response.data.error === "TOKEN_EXPIRED") {
          history.push("/sign-in");
        }
        setLoading(false);
        setError(true);
        setrErrMsg(
          err.response
            ? err.response.data.message
            : err.message || "Check your Internet connection"
        );
        addToast(
          err.response
            ? err.response.data.message
            : err.message || "Check your Internet connection",
          { appearance: "error" }
        );

        setTimeout(() => {
          setError(false);
        }, 3000);
      });
  };

  useEffect(() => {
    getListOfRequests();
  }, [getListOfRequests]);

  useEffect(() => {
    getListOfOffers();
  }, [getListOfOffers]);

  return {
    handleChange,
    handleRequestFilter,
    handleOfferFilter,
    handleChangeSell,
    sellCurrency,
    setSellData,
    setQueryData,
    setOfferFilter,
    queryData,
    sellData,
    requestList,
    requestFilter,
    offerList,
    offerFilter,
    loading,
  };
}

export default RequestProvider;
