import _ from "lodash";
import { GET, PUT, POST, PATCH } from "../core/config/Api";

export const sendOtp = (data) => {
  return POST(`user/send-otp-to-email`, data).then((res) => {
    return res?.data;
  });
};

export const verifyOtp = (data) => {
  return POST(`user/verify-email`, data).then((res) => {
    return res?.data;
  });
};

export const register = (data) => {
  return POST(`user`, data).then((res) => {
    return res;
  });
};

export const login = (data) => {
  return POST(`user/login`, data).then((res) => {
    return res?.data;
  });
};

export const forgotPassword = (data) => {
  return POST(`user/forgot-password`, data).then((res) => {
    return res?.data;
  });
};

export const resetPassword = (data) => {
  return POST(`user/reset-password`, data).then((res) => {
    return res?.data;
  });
};

export const changePassword = (data) => {
  return POST(`user/change-password`, data).then((res) => {
    return res?.data;
  });
};

export const changeEmail = (data) => {
  return PATCH(`user/change-email`, data).then((res) => {
    return res?.data;
  });
};

export const userProfile = (id) => {
  return GET(`user/${id}`).then((res) => {
    return res?.data;
  });
};

export const updateProfile = (data) => {
  return PATCH(`user`, data).then((res) => {
    return res?.data;
  });
};
