import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
} from "react-router-dom";
import CheckboxMain from "../../components/Checkbox";
import BasicTextField from "../../components/BasicTextField";
import WalletDropdown from "../../components/WalletDropdown";
import style from "../../styling/styles.module.css";
import fundWalletIllustration from "../../images/fundWalletIllustration.svg";
import "../../styling/styles.css";
import WalletProvider from "../wallet/provider";
import { countryCurrency } from "../../utils/countryCurrencyList";

export default function FundWallet() {
  const {
    walletList,
    fundWalletData,
    loading,
    handleChangeFund,
    setFundWalletData,
    fundWallet,
  } = WalletProvider();

  return (
    <div className={style.wrapContainerBorder}>
      <img
        className={style.illustrationImage}
        src={fundWalletIllustration}
      ></img>
      <h4>Fund Your Wallet</h4>
      <p>Input your card details to fund your wallet.</p>
      <form onSubmit={fundWallet} className="withdraw-form">
        <div className="">
          <WalletDropdown
            walletList={walletList}
            fundWalletData={fundWalletData}
            setFundWalletData={setFundWalletData}
            placeholder="Wallet To Fund"
          />
        </div>
        <div className="input-container">
          <BasicTextField
            label="Fund Amount"
            name="amount"
            onChange={handleChangeFund}
            type="number"
            value={fundWalletData.amount}
          />
        </div>
        <div className="input-container last">
          <BasicTextField label="Name On Card" />
        </div>
        <div className="input-container last">
          <BasicTextField label="Card Number" />
        </div>
        <ul className="security-code">
          <li className="">
            <BasicTextField label="Expiration Date" />
          </li>
          <li className="">
            <BasicTextField label="Security Code (CVC)" />
          </li>
        </ul>
        <CheckboxMain label="Save Card Details" />
        <div className="form-button">
          <button className={style.buttonPrimary}>
            {loading ? "Loading..." : "Fund Wallet"}
          </button>
          <div className={style.seperator}>
            <span className={style.seperatorText}>or</span>
            <span className={style.seperatorDivider}></span>
          </div>
          <button className={style.buttonUnderline}>Cancel</button>
        </div>
      </form>
    </div>
  );
}
