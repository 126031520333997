import { ADD_USER, SET_USERS } from "../../types/types";

const initialState = {
  users: {
    limit: 30,
    currentPage: 1,
    users: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_USER:
      return {
        ...state,
        users: {
          ...state.users,
          users: [
            {
              ...action.user,
            },

            ...state.users.users,
          ],
        },
      };

    case SET_USERS:
      return {
        ...state,
        users: action.users,
      };

    default:
      return state;
  }
};
