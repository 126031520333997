import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import UseRadioGroup from "../../components/RadioGroup";
import BasicTextField from "../../components/BasicTextField";
import CurrencyDropdown from "../../components/CurrencyDropdown";
import style from "../../styling/styles.module.css";
import RequestProvider from "./RequestProvider";
import { countryCurrency } from "../../utils/countryCurrencyList";
import { NavLink } from "react-router-dom";
import LoadingIcon from "./../../components/LoadingIcon";

export default function Offers() {
  const { handleOfferFilter, setOfferFilter, offerList, offerFilter, loading } =
    RequestProvider();
  return (
    <div className={style.listWrap}>
      <div className={style.listWrapFilter}>
        <div className="offer-type">
          <h4>Offers To See</h4>
          <div className="radio-button">
            <UseRadioGroup
              handleChange={handleOfferFilter}
              requestType={offerFilter.requestType}
              firstLabel="Sell Offers"
              secondLabel="Buy Offers"
            />
          </div>
        </div>
        <div className="currency">
          <h4>Select Currency</h4>
          <div className="currency-select">
            <CurrencyDropdown
              queryData={offerFilter}
              requestList={countryCurrency}
              placeholder="Currency To Sell"
              setQueryData={setOfferFilter}
              direction={"fromCurrency"}
              flag={"fromFlag"}
            />
            <CurrencyDropdown
              queryData={offerFilter}
              requestList={countryCurrency}
              placeholder="Currency To Buy"
              setQueryData={setOfferFilter}
              direction={"toCurrency"}
              flag={"toFlag"}
            />
          </div>
        </div>
        <div className="filter-rate">
          <h4>Filter Rate</h4>
          <div className="filter-rate-input">
            <BasicTextField
              name="rate"
              onChange={handleOfferFilter}
              type="number"
              label="550/USD"
              value={offerFilter.rate}
            />
          </div>
        </div>
        <div className="minimum-amount">
          <h4>Minimum Amount</h4>
          <div className="minimum-amount-input">
            <BasicTextField
              name="minimumAmount"
              onChange={handleOfferFilter}
              type="number"
              label="5000"
              value={offerFilter.minimumAmount}
            />
          </div>
        </div>
      </div>
      <div className={style.listWrapAvailableOffers}>
        {loading ? (
          <div className={style.centerLoader}>
            <ul>
              <li>
                <p>
                  <LoadingIcon />
                </p>
              </li>
            </ul>
          </div>
        ) : (
          offerList.length === 0 && (
            <div className={style.listWrapOffer}>
              <ul>
                <li>
                  <p>Offer list is empty !</p>
                </li>
              </ul>
            </div>
          )
        )}
        <div className={style.listWrapOffer}>
          {offerList?.length > 0 &&
            offerList.map((offer) => (
              <>
                <ul>
                  <li>
                    <img
                      alt="Nigerian"
                      src="http://purecatamphetamine.github.io/country-flag-icons/3x2/NG.svg"
                    />
                  </li>
                  <li>
                    <h3>{offer.trader.username}</h3>
                    <p>
                      <span>
                        <CheckCircleIcon sx={{ fontSize: 16 }} />
                      </span>
                      verified user
                    </p>
                  </li>
                  <li>
                    <h3>
                      {`${offer.rate}/`}
                      <span className="base-currency">
                        {offer.fromCurrency.currency}
                      </span>
                    </h3>
                    <p>
                      <span></span>exchange rate
                    </p>
                  </li>
                  <li>
                    <h3>{`${offer.fromCurrency.symbol} ${offer.minimumAmount}/`}</h3>
                    <p>
                      <span></span>min amount
                    </p>
                  </li>
                  <li>
                    <h3>{`${offer.fromCurrency.symbol} ${offer.available}/`}</h3>
                    <p>
                      <span></span>trade volume
                    </p>
                  </li>
                  <li>
                    <button className={style.buttonTertiary}>
                      Sell
                      <span className={style.formLink}>
                        <NavLink to="/buy-request">
                          {" "}
                          <ArrowCircleRightIcon />
                        </NavLink>
                      </span>
                    </button>
                  </li>
                </ul>
                <ul>
                  <li>
                    <img
                      alt="Nigerian"
                      src="http://purecatamphetamine.github.io/country-flag-icons/3x2/NG.svg"
                    />
                  </li>
                  <li>
                    <h3>{offer.trader.username}</h3>
                    <p>
                      <span>
                        <CheckCircleIcon sx={{ fontSize: 16 }} />
                      </span>
                      verified user
                    </p>
                  </li>
                  <li>
                    <h3>
                      {`${offer.rate}/`}
                      <span className="base-currency">
                        {offer.toCurrency.currency}
                      </span>
                    </h3>
                    <p>
                      <span></span>exchange rate
                    </p>
                  </li>
                  <li>
                    <h3>{`${offer.toCurrency.symbol} ${offer.minimumAmount}/`}</h3>
                    <p>
                      <span></span>min amount
                    </p>
                  </li>
                  <li>
                    <h3>{`${offer.toCurrency.symbol} ${offer.available}/`}</h3>
                    <p>
                      <span></span>trade volume
                    </p>
                  </li>
                  <li>
                    <button className={style.buttonTertiary}>
                      Buy
                      <span className={style.formLink}>
                        <NavLink to="/buy-request">
                          {" "}
                          <ArrowCircleRightIcon />
                        </NavLink>
                      </span>
                    </button>
                  </li>
                </ul>
              </>
            ))}
        </div>
      </div>
    </div>
  );
}
