import { useState } from "react";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import * as AuthServices from "../../../services/auth";

export const PasswordResetLinkProvider = () => {
  const { addToast } = useToasts();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errMsg, setrErrMsg] = useState("");
  const history = useHistory();

  const handleSubmit = (e, email) => {
    e.preventDefault();
    setLoading(true);
    AuthServices.sendOtp({ email })
      .then((res) => {
        setLoading(false);
        setError(false);
        addToast(res?.message, { appearance: "success" });
        history.push({
          pathname: "/password-reset",
          state: {
            email,
          },
        });
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
        setrErrMsg(
          err.response
            ? err.response.data.message
            : err.message || "Check your Internet connection"
        );
        addToast(
          err.response
            ? err.response.data.message
            : err.message || "Check your Internet connection",
          { appearance: "error" }
        );
        setTimeout(() => {
          setError(false);
        }, 3000);
      });
  };

  return {
    loading,
    errMsg,
    error,
    handleSubmit,
  };
};
