import React from "react";
import RefreshIcon from "@mui/icons-material/Refresh";

function LoadingIcon() {
  return (
    <RefreshIcon
      color="primary"
      fontSize="large"
      sx={{
        animation: "spin 2s linear infinite",
        "@keyframes spin": {
          "0%": {
            transform: "rotate(0deg)",
          },
          "100%": {
            transform: "rotate(360deg)",
          },
        },
      }}
    />
  );
}

export default LoadingIcon;
