import _ from "lodash";
import { GET, PUT, POST, PATCH, DELETE } from "../core/config/Api";

export const createWallet = (data) => {
  return POST(`wallet`, data).then((res) => {
    return res?.data;
  });
};

export const deactivateWallet = (id, data) => {
  return PATCH(`wallet/${id}`, data).then((res) => {
    return res?.data;
  });
};

export const listWallets = () => {
  return GET(`wallets`).then((res) => {
    return res?.data;
  });
};

export const supportedCountries = () => {
  return GET(`wallet/supported-currencies`).then((res) => {
    return res?.data;
  });
};

export const viewWallet = (id) => {
  return GET(`wallet/${id}`).then((res) => {
    return res?.data;
  });
};

export const deleteWallet = (id) => {
  return DELETE(`wallet/${id}`).then((res) => {
    return res?.data;
  });
};
