import { TRANSACTION_LIST } from "../../types/types";
const initialStateTransaction = {
  transactionList: [],
};

function transactionReducer(state = initialStateTransaction, action) {
  switch (action.type) {
    case TRANSACTION_LIST:
      return {
        transactionList: action.payload.transactionList,
        status: action.payload.status,
      };

    default:
      return state;
  }
}

export default transactionReducer;
