import React, { useState, useEffect, useCallback } from "react";
import { useToasts } from "react-toast-notifications";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import wallet from "../../store/actions/wallet/wallet";
import transaction from "../../store/actions/transaction/transaction";

function HomeProvider() {
  const { addToast } = useToasts();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errMsg, setrErrMsg] = useState("");
  const history = useHistory();

  const { walletList } = useSelector((state) => state.wallet || []);
  const { transactionList } = useSelector((state) => state.transaction || []);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(wallet())
      .then((res) => {
        setLoading(false);
        setError(false);
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response.data.error === "TOKEN_EXPIRED") {
          history.push("/sign-in");
        }
        setLoading(false);
        setError(true);
        setrErrMsg(
          err.response
            ? err.response.data.message
            : err.message || "Check your Internet connection"
        );
        addToast(
          err.response
            ? err.response.data.message
            : err.message || "Check your Internet connection",
          { appearance: "error" }
        );

        setTimeout(() => {
          setError(false);
        }, 3000);
      });
  }, []);

  useEffect(() => {
    if (walletList.length > 0) {
      setLoading(true);
      const walletObj = {
        walletId: walletList?.[0]?._id,
        status: "SUCCESS",
      };

      dispatch(transaction(walletObj))
        .then((res) => {
          setLoading(false);
          setError(false);
        })
        .catch((err) => {
          console.log(err.response);
          if (err.response.data.error === "TOKEN_EXPIRED") {
            history.push("/sign-in");
          }
          setLoading(false);
          setError(true);
          setrErrMsg(
            err.response
              ? err.response.data.message
              : err.message || "Check your Internet connection"
          );
          addToast(
            err.response
              ? err.response.data.message
              : err.message || "Check your Internet connection",
            { appearance: "error" }
          );

          setTimeout(() => {
            setError(false);
          }, 3000);
        });
    }
  }, [walletList]);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return {
    walletList,
    transactionList,
    settings,
    loading,
  };
}

export default HomeProvider;
