import { NavLink } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "@mui/icons-material/Google";
import AppleIcon from "@mui/icons-material/Apple";
import logo from "../../../logo.svg";
import background from "../../../background-image.jpg";
import style from "../../../styling/styles.module.css";
import "../../../styling/styles.css";
import { useLocation } from "react-router-dom";
import { PasswordResetLinkProvider } from "./provider";

export default function PasswordResetLink() {
  const location = useLocation();
  const email = location?.state?.email;
  const { handleSubmit, loading } = PasswordResetLinkProvider();

  return (
    <div className={style.userWrap}>
      <div className={style.userWrapFiftyLeft}>
        <div className="logo">
          <img src={logo}></img>
        </div>
        <form className={style.formField}>
          <h3>A Reset Link Has Been Sent</h3>
          <p>
            {`An email has been sent to ${email} . Please follow the
            instruction.`}
          </p>
          <button className="underline" onClick={(e) => handleSubmit(e, email)}>
            {loading ? "Loading..." : "Resend Email"}
          </button>
          <span className={style.formLink}>
            Already have an account?<NavLink to=""> Sign In</NavLink>
          </span>
        </form>
        <div className={style.socialLogin}>
          <h4>Social Login</h4>
          <ul>
            <li>
              <button className="facebook">
                <FacebookIcon sx={{ fontSize: 30 }} />
              </button>
            </li>
            <li>
              <button className="google">
                <GoogleIcon sx={{ fontSize: 30 }} />
              </button>
            </li>
            <li>
              <button className="apple">
                <AppleIcon sx={{ fontSize: 30 }} />
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div class={style.userWrapFiftyRight}>
        <img src={background}></img>
      </div>
    </div>
  );
}
