import { useState } from "react";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import { POST } from "../../../core/config/Api";
import {
  validateEmail,
  validateName,
  validatePassword,
  validatePhoneNumber,
} from "../../../utils/credentialsValidator";
import * as AuthServices from "../../../services/auth";

export const SignUpProvider = () => {
  const { addToast } = useToasts();
  const [credentials, setCredentials] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phoneNumber: "",
    password: "",
    userAgent: "WEB",
    iso2: "NG",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errMsg, setrErrMsg] = useState("");
  const history = useHistory();

  const handleChange = (e) => {
    const { id, value } = e.target;
    setCredentials({ ...credentials, [id]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    // Validation will go here
    const data = {};
    data.userAgent = credentials.userAgent;
    data.iso2 = credentials.iso2;
    if (validateEmail(credentials.email)) {
      data.email = credentials.email;
    } else {
      addToast("Please, enter valid Email!", { appearance: "error" });
      setLoading(false);
      return;
    }

    if (validateName(credentials.firstname)) {
      data.firstname = credentials.firstname;
    } else {
      addToast("Please, enter valid first name!", { appearance: "error" });
      setLoading(false);
      return;
    }

    if (validateName(credentials.lastname)) {
      data.lastname = credentials.lastname;
    } else {
      addToast("Please, enter valid last name!", { appearance: "error" });
      setLoading(false);
      return;
    }

    if (validatePhoneNumber(credentials.phoneNumber)) {
      data.phoneNumber = credentials.phoneNumber;
    } else {
      addToast("Please, enter valid Phone Number!", { appearance: "error" });
      setLoading(false);
      return;
    }

    if (validatePassword(credentials.password).isPassed) {
      data.password = credentials.password;
    } else {
      addToast(validatePassword(credentials.password).message, {
        appearance: "error",
      });
      setLoading(false);
      return;
    }

    AuthServices.register(data)
      .then((res) => {
        setLoading(false);
        setError(false);
        addToast(res?.data?.message, { appearance: "success" });
        AuthServices.sendOtp({ email: credentials.email })
          .then((res) => {
            addToast(res?.message, { appearance: "success" });
          })
          .catch((err) => {
            addToast(
              err.response
                ? err.response.data.message
                : err.message || "Check your Internet connection",
              { appearance: "error" }
            );
          });

        setCredentials({
          firstname: "",
          lastname: "",
          email: "",
          phoneNumber: "",
          password: "",
          userAgent: "WEB",
          iso2: "NG",
        });

        history.push("/verify-otp");
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response.data.error === "TOKEN_EXPIRED") {
          history.push("/sign-in");
        }
        setLoading(false);
        setError(true);
        setrErrMsg(
          err.response
            ? err.response.data.message
            : err.message || "Check your Internet connection"
        );
        addToast(
          err.response
            ? err.response.data.message
            : err.message || "Check your Internet connection",
          { appearance: "error" }
        );

        setTimeout(() => {
          setError(false);
        }, 3000);
      });
  };

  return {
    credentials,
    loading,
    errMsg,
    error,
    handleSubmit,
    handleChange,
  };
};
