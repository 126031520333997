import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
} from "react-router-dom";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import style from "../../styling/styles.css";
import WalletProvider from "./provider";

export default function MyWallet() {
  const { walletList } = WalletProvider();

  return (
    <div className="wallets-wrap">
      <div className="wallets">
        {walletList?.map((wallet, index) => (
          <div
            className={`wallet ${
              index % 3 === 1
                ? "gradient-one"
                : index % 3 === 2
                ? "gradient-two"
                : index % 3 === 3
                ? "gradient-three"
                : "gradient-five"
            }`}
          >
            <div className="currency">
              <p>
                <span>
                  <img
                    alt={`${wallet?.currency?.countryName} ${wallet?.currency?.currencyName}`}
                    src={`${wallet?.currency.flag}`}
                  />
                </span>
                {`${wallet?.currency?.currencyName} Wallet`}
              </p>
            </div>
            <div className="currency-amount">
              {" "}
              {`${wallet?.currency?.symbol} ${wallet?.balance}`}
            </div>
            <div className="wallet-id">{`Wallet ID: ${wallet._id}`}</div>
            <NavLink
              className="view-wallet"
              to={{
                pathname: "/currency-wallet",
                state: { wallet },
              }}
            >
              View Wallet
              <span>
                <ArrowCircleRightIcon />
              </span>
            </NavLink>
          </div>
        ))}

        <NavLink className="wallet create-wallet" to="/add-wallet">
          <div className="currency">
            <button className="add-wallet">
              <span>
                <AddCircleIcon sx={{ fontSize: 60 }} />
              </span>
              <h4>Add Wallet</h4>
            </button>
          </div>
        </NavLink>
      </div>
    </div>
  );
}
