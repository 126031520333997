import { useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import { POST } from "../../../core/config/Api";
import {
  validateEmail,
  validateGender,
  validateName,
  validatePassword,
} from "../../../utils/credentialsValidator";
import * as AuthServices from "../../../services/auth";
import { getUserProfile } from "../../../utils/userProfile";
import { validateOtp } from "./../../../utils/credentialsValidator";
import { useHistory } from "react-router-dom";

export const MyProfileProvider = () => {
  const { addToast } = useToasts();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errMsg, setrErrMsg] = useState("");
  const [user, setUser] = useState({});
  const [gender, setGender] = useState({});
  const [emailUpdate, setEmailUpdate] = useState({ email: "" });
  const [securityQuestionAndAnswer, setSecurityQuestionAndAnswer] = useState(
    {}
  );
  const [iso2, setIso2] = useState({});
  const [credentials, setCredentials] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [showUpdateEmailStep, setShowUpdateEmailStep] = useState(false);
  const [otp, setOtp] = useState("");

  useEffect(() => {
    const userProfile = getUserProfile();
    setUser({ ...userProfile });
    if (userProfile?.iso2) {
      setIso2({ iso2: userProfile?.iso2 });
    }
    if (userProfile?.gender) {
      setGender({ gender: userProfile?.gender });
    }

    if (userProfile?.securityQuestion && userProfile?.securityQuestionAnswer) {
      setSecurityQuestionAndAnswer({
        securityQuestion: userProfile?.securityQuestion,
        securityQuestionAnswer: userProfile?.securityQuestionAnswer,
      });
    }
  }, []);

  const handleChangePassword = (e) => {
    const { id, value } = e.target;
    setCredentials({ ...credentials, [id]: value });
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setUser({ ...user, [id]: value });
  };

  const handleChangeGender = (e) => {
    const { id, value } = e.target;
    setGender({ ...gender, [id]: value });
  };

  const handleChangeIso2 = (e) => {
    const { id, value } = e.target;
    setIso2({ ...iso2, [id]: value });
  };

  const handleChangeOtp = (e) => {
    const { value } = e.target;
    setOtp(value);
  };

  const handleChangeEmailUpdate = (e) => {
    const { id, value } = e.target;
    setEmailUpdate({ ...emailUpdate, [id]: value });
  };

  const handleChangeSecurityQuestionAndAnswer = (e) => {
    const { id, value } = e.target;
    setSecurityQuestionAndAnswer({ ...securityQuestionAndAnswer, [id]: value });
  };

  const handleEditName = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {};
    if (validateName(user.firstname)) {
      data.firstname = user.firstname;
    } else {
      addToast("Please, enter valid first name!", { appearance: "error" });
      setLoading(false);
      return;
    }
    if (validateName(user.lastname)) {
      data.lastname = user.lastname;
    } else {
      addToast("Please, enter valid last name!", { appearance: "error" });
      setLoading(false);
      return;
    }

    update(data);
  };

  const handleEditSecurityQuestionAndAnswer = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {};

    if (validateName(securityQuestionAndAnswer.securityQuestion)) {
      data.securityQuestion = securityQuestionAndAnswer.securityQuestion;
    } else {
      addToast("Please, enter valid security Question!", {
        appearance: "error",
      });
      setLoading(false);
      return;
    }
    if (securityQuestionAndAnswer.securityQuestionAnswer) {
      data.securityQuestionAnswer =
        securityQuestionAndAnswer.securityQuestionAnswer;
    } else {
      addToast("Please, enter valid security answer!", {
        appearance: "error",
      });
      setLoading(false);
      return;
    }

    update(data);
  };

  const handleEditUsername = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {};
    if (validateName(user.username)) {
      data.username = user.username;
    } else {
      addToast("Please, enter valid Username!", { appearance: "error" });
      setLoading(false);
      return;
    }

    update(data);
  };

  const handleEditGender = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {};

    if (validateGender(gender.gender).isPassed) {
      data.gender = gender.gender;
    } else {
      addToast(validateGender(gender.gender).message, { appearance: "error" });
      setLoading(false);
      return;
    }

    update(data);
  };
  const handleEditIso2 = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {};

    if (iso2.iso2.length === 2) {
      data.iso2 = iso2.iso2.toUpperCase();
    } else {
      addToast("Please, enter a valid ISO2", { appearance: "error" });
      setLoading(false);
      return;
    }

    update(data);
  };

  const handleEditEmail = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {};

    data.email = user.email;

    if (validateOtp(otp).isPassed) {
      data.otp = otp;
    } else {
      addToast(validateOtp(otp).message, { appearance: "error" });
      setLoading(false);
      return;
    }

    updateEmail(data);
  };

  const updateEmail = (data) => {
    AuthServices.changeEmail(data)
      .then((response) => {
        setLoading(false);
        setError(false);

        AuthServices.userProfile(user?._id).then((res) => {
          setUser({
            ...res.data,
          });
          const user = res?.data;
          localStorage.setItem("monirate_user", JSON.stringify(user));
          addToast(res?.message, { appearance: "success" });
        });
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response.data.error === "TOKEN_EXPIRED") {
          history.push("/sign-in");
        }
        if (err.response.data.error === "TOKEN_EXPIRED") {
          history.push("/sign-in");
        }
        setLoading(false);
        setError(true);
        setrErrMsg(
          err.response
            ? err.response.data.message
            : err.message || "Check your Internet connection"
        );
        addToast(
          err.response
            ? err.response.data.message
            : err.message || "Check your Internet connection",
          { appearance: "error" }
        );

        setTimeout(() => {
          setError(false);
        }, 3000);
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response.data.error === "TOKEN_EXPIRED") {
          history.push("/sign-in");
        }
        setLoading(false);
        setError(true);
        setrErrMsg(
          err.response
            ? err.response.data.message
            : err.message || "Check your Internet connection"
        );
        addToast(
          err.response
            ? err.response.data.message
            : err.message || "Check your Internet connection",
          { appearance: "error" }
        );

        setTimeout(() => {
          setError(false);
        }, 3000);
      });
  };

  const sendOtpToNewEmail = () => {
    const data = {};

    if (validateEmail(emailUpdate.email)) {
      data.email = emailUpdate.email;
    } else {
      addToast("Please, enter valid Email !", { appearance: "error" });
      setLoading(false);
      return;
    }

    setLoading(true);
    AuthServices.sendOtp(data)
      .then((res) => {
        setLoading(false);
        setError(false);
        addToast(res?.message, { appearance: "success" });
        setShowUpdateEmailStep(true);
        setUser({ ...user, email: emailUpdate.email });
      })
      .catch((err) => {
        setShowUpdateEmailStep(false);
        console.log(err.response);
        if (err.response.data.error === "TOKEN_EXPIRED") {
          history.push("/sign-in");
        }
        setLoading(false);
        setError(true);
        setrErrMsg(
          err.response
            ? err.response.data.message
            : err.message || "Check your Internet connection"
        );
        addToast(
          err.response
            ? err.response.data.message
            : err.message || "Check your Internet connection",
          { appearance: "error" }
        );

        setTimeout(() => {
          setError(false);
        }, 3000);
      });
  };

  const update = (data) => {
    AuthServices.updateProfile(data)
      .then((response) => {
        setLoading(false);
        setError(false);

        AuthServices.userProfile(user?._id).then((res) => {
          setUser({
            ...res.data,
          });
          const user = res?.data;
          localStorage.setItem("monirate_user", JSON.stringify(user));
          addToast(res?.message, { appearance: "success" });
        });
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response.data.error === "TOKEN_EXPIRED") {
          history.push("/sign-in");
        }
        setLoading(false);
        setError(true);
        setrErrMsg(
          err.response
            ? err.response.data.message
            : err.message || "Check your Internet connection"
        );
        addToast(
          err.response
            ? err.response.data.message
            : err.message || "Check your Internet connection",
          { appearance: "error" }
        );

        setTimeout(() => {
          setError(false);
        }, 3000);
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response.data.error === "TOKEN_EXPIRED") {
          history.push("/sign-in");
        }
        setLoading(false);
        setError(true);
        setrErrMsg(
          err.response
            ? err.response.data.message
            : err.message || "Check your Internet connection"
        );
        addToast(
          err.response
            ? err.response.data.message
            : err.message || "Check your Internet connection",
          { appearance: "error" }
        );

        setTimeout(() => {
          setError(false);
        }, 3000);
      });
  };

  const handleEditPassword = (e) => {
    e.preventDefault();
    setLoading(true);

    // Validation will go here
    const data = {};

    if (validatePassword(credentials.confirmPassword).isPassed) {
      data.oldPassword = credentials.oldPassword;
    } else {
      addToast(validatePassword(credentials.confirmPassword).message, {
        appearance: "error",
      });
      setLoading(false);
      return;
    }

    if (credentials.newPassword === credentials.confirmPassword) {
      data.oldPassword = credentials.oldPassword;
    } else {
      setLoading(false);
      addToast("Passwords do not match!", { appearance: "error" });
      return;
    }

    if (credentials.oldPassword === credentials.newPassword) {
      setLoading(false);
      addToast("Passwords are the same, please enter a new password!", {
        appearance: "error",
      });
      return;
    }
    if (validatePassword(credentials.newPassword).isPassed) {
      data.newPassword = credentials.newPassword;
    } else {
      addToast(validatePassword(credentials.newPassword).message, {
        appearance: "error",
      });
      setLoading(false);
      return;
    }
    if (validatePassword(credentials.oldPassword).isPassed) {
      data.oldPassword = credentials.oldPassword;
    } else {
      addToast(validatePassword(credentials.oldPassword).message, {
        appearance: "error",
      });
      setLoading(false);
      return;
    }

    AuthServices.changePassword(data)
      .then((res) => {
        setLoading(false);
        setError(false);
        setCredentials({
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
        addToast(res?.message, { appearance: "success" });
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
        setrErrMsg(
          err.response
            ? err.response.data.message
            : err.message || "Check your Internet connection"
        );
        addToast(
          err.response
            ? err.response.data.message
            : err.message || "Check your Internet connection",
          { appearance: "error" }
        );

        setTimeout(() => {
          setError(false);
        }, 3000);
      });
  };

  const { newPassword, oldPassword, confirmPassword } = credentials;
  const { securityQuestion, securityQuestionAnswer } =
    securityQuestionAndAnswer;

  return {
    loading,
    errMsg,
    error,
    user,
    newPassword,
    oldPassword,
    confirmPassword,
    gender,
    securityQuestion,
    securityQuestionAnswer,
    iso2,
    otp,
    emailUpdate,
    showUpdateEmailStep,
    handleChange,
    handleChangeGender,
    handleChangeSecurityQuestionAndAnswer,
    handleEditName,
    handleEditUsername,
    handleEditEmail,
    handleEditPassword,
    handleChangePassword,
    handleEditGender,
    handleEditSecurityQuestionAndAnswer,
    handleChangeIso2,
    handleEditIso2,
    handleChangeOtp,
    handleChangeEmailUpdate,
    sendOtpToNewEmail,
  };
};
