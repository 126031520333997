import { useState } from "react";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import * as AuthServices from "../../../services/auth";

export const ChangePasswordProvider = () => {
  const { addToast } = useToasts();
  const [credentials, setCredentials] = useState({
    oldPassword: "",
    newPassword: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errMsg, setrErrMsg] = useState("");
  const history = useHistory();

  const handleChange = (e) => {
    const { id, value } = e.target;
    setCredentials({ ...credentials, [id]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    // Validation will go here
    const data = {};
    if (credentials.oldPassword === credentials.newPassword) {
      setLoading(false);
      addToast("Passwords are the same, please enter a new password!", {
        appearance: "error",
      });
      return;
    }

    data.oldPassword = credentials.oldPassword;
    data.newPassword = credentials.newPassword;

    AuthServices.changePassword(data)
      .then((res) => {
        setLoading(false);
        setError(false);
        addToast(res?.message, { appearance: "success" });

        setCredentials({
          oldPassword: "",
          newPassword: "",
        });

        history.push("/sign-in");
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
        setrErrMsg(
          err.response
            ? err.response.data.message
            : err.message || "Check your Internet connection"
        );
        addToast(
          err.response
            ? err.response.data.message
            : err.message || "Check your Internet connection",
          { appearance: "error" }
        );

        setTimeout(() => {
          setError(false);
        }, 3000);
      });
  };

  return {
    credentials,
    loading,
    errMsg,
    error,
    handleSubmit,
    handleChange,
  };
};
