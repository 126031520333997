import { NavLink } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "@mui/icons-material/Google";
import AppleIcon from "@mui/icons-material/Apple";
import BasicTextField from "../../../components/BasicTextField";
import logo from "../../../logo.svg";
import background from "../../../background-image.jpg";
import style from "../../../styling/styles.module.css";
import "../../../styling/styles.css";
import { SignUpProvider } from "./provider";
import PasswordInput from "./../../../components/PasswordInput";

export default function SignUp() {
  const { handleChange, handleSubmit, credentials, loading } = SignUpProvider();

  return (
    <div className={style.userWrap}>
      <div className={style.userWrapFiftyLeft}>
        <div className="logo">
          <img src={logo}></img>
        </div>
        <form className={style.formField} onSubmit={handleSubmit}>
          <h3>Monirates is easy and very fast</h3>
          <div className="input-container">
            <BasicTextField
              id="firstname"
              onChange={handleChange}
              type="text"
              label="Firstname"
              value={credentials.firstName}
            />
          </div>
          <div className="input-container">
            <BasicTextField
              id="lastname"
              onChange={handleChange}
              type="text"
              label="Lastname"
              value={credentials.lastName}
            />
          </div>
          <div className="input-container">
            <BasicTextField
              id="email"
              onChange={handleChange}
              type="email"
              label="Email Address"
              value={credentials.email}
            />
          </div>
          <div className="input-container">
            <BasicTextField
              id="phoneNumber"
              onChange={handleChange}
              type="phoneNumber"
              label="Phone Number"
              value={credentials.phoneNumber}
            />
          </div>

          <div className="input-container last">
            <PasswordInput
              id={"password"}
              handleChange={handleChange}
              passwordValue={credentials.password}
              label={"Password"}
            />
          </div>
          <button className="primary">
            {loading ? "Loading..." : "Sign Up"}
          </button>

          <span className={style.formLink}>
            Already have an account?<NavLink to="/sign-in">Sign In</NavLink>
          </span>
        </form>
        {/* <div className={style.socialLogin}>
          <h4>Social Login</h4>
          <ul>
            <li>
              <button className="facebook">
                <FacebookIcon sx={{ fontSize: 30 }} />
              </button>
            </li>
            <li>
              <button className="google">
                <GoogleIcon sx={{ fontSize: 30 }} />
              </button>
            </li>
            <li>
              <button className="apple">
                <AppleIcon sx={{ fontSize: 30 }} />
              </button>
            </li>
          </ul>
        </div> */}
      </div>
      <div class={style.userWrapFiftyRight}>
        <img src={background}></img>
      </div>
    </div>
  );
}
